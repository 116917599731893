<template>
  <div>
    <div class="contain">
      <div class="h1">收藏</div>
      <div class="h1_line"></div>
      <el-table
        :data="list"
        style="width: 100%"
        header-cell-class-name="tabe_header"
      >
        <el-table-column label="名称" width="180">
          <template slot-scope="scope">
            <div class="box1">
              <img
                v-if="scope.row.ext == '.doc'"
                src="@/assets/image/icon113.png"
                alt=""
                class="contain1_title_img1"
              />
              <img
                v-else-if="scope.row.ext == '.pdf'"
                src="@/assets/image/icon118.png"
                alt=""
                class="contain1_title_img1"
              />
              <img
                v-else-if="scope.row.ext == '.ppt'"
                src="@/assets/image/icon116.png"
                alt=""
                class="contain1_title_img1"
              />
              <img
                v-else-if="scope.row.ext == '.xls'"
                src="@/assets/image/icon114.png"
                alt=""
                class="contain1_title_img1"
              />
              <img
                v-else-if="scope.row.ext == '.txt'"
                src="@/assets/image/icon115.png"
                alt=""
                class="contain1_title_img1"
              />
              <img
                v-else
                src="@/assets/image/icon117.png"
                alt=""
                class="contain1_title_img1"
              />

              <div style="color:dodgerblue;cursor: pointer;" @click="goDetail(scope.row)">{{ scope.row.title }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="评分" width="140">
          <template slot-scope="scope">
            <el-rate
              v-model="scope.row.score"
              disabled
              text-color="#ff9900"
              score-template="{value}"
            >
            </el-rate>
          </template>
        </el-table-column>
        <el-table-column label="浏览" prop="view_count"> </el-table-column>
        <el-table-column label="价格" prop="price"> </el-table-column>
        <el-table-column label="大小">
          <template slot-scope="scope">{{
            formatBytes(scope.row.size)
          }}</template>
        </el-table-column>
        <el-table-column prop="created_at" label="收藏时间" width="160">
          <template slot-scope="scope">
            <el-tooltip
              :content="formatDatetime(scope.row.created_at)"
              placement="top"
            >
              <span>{{ formatRelativeTime(scope.row.created_at) }}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100" align="center">
          <template slot-scope="scope">
            <img
              src="@/assets/image/icon56.png"
              alt=""
              @click="del(scope)"
              class="del"
            />
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination_contain">
        <div>共{{ total || 0 }}项数据</div>
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page="page"
          :page-size="size"
          :total="total"
          @current-change="getList"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { postJSON } from "/utils/request";
import { formatBytes, formatDatetime, formatRelativeTime } from "/utils/utils";
export default {
  name: "collect",
  data() {
    return {
      list: [],
      total: 0,
      page: 1,
      size: 10
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    formatBytes,
    formatDatetime,
    formatRelativeTime,
    del(scope) {
      postJSON(`/favorite/del`, { document_id: Number(scope.row.document_id) }).then(
        (res) => {
          console.log("是否收藏", res);
          this.page = 1
          this.getList();
        }
      );
    },
    goDetail(rew) {
      if (rew.deleted_at == undefined) {
        this.$router.push(`docDetail?id=${rew.document_id}`);
      } else {
        this.$message.warning("该文件已被作者删除");
      }
    },
    getList() {
      postJSON("/favorite/lists",{ "page":this.page, "size": this.size }).then(res => {
        if (!res.favorites) {
          this.list = [];
          return;
        }
        res.favorites.forEach((item) => {
          item["score"] = item.score / 100;
          item["view_count"] = item.view_count || 0;
          item["price"] = item.price || 0;
        });
        this.list = res.favorites;
        this.total = res.total;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.contain {
  width: 1005px;
  height: 934px;
  background: #ffffff;
  border-radius: 6px;
  padding: 20px;
  box-sizing: border-box;
  .h1 {
    font-size: 18px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    color: #333333;
    margin-bottom: 20px;
  }
  .h1_line {
    height: 1px;
    background: #f6f7f8;
    margin-bottom: 20px;
  }
  ::v-deep .tabe_header {
    background: #f6f7f8 !important;
  }
  .box1 {
    display: flex;
    align-items: center;
    img {
      width: 22px;
      height: 22px;
      margin-right: 10px;
    }
    div:nth-child(2) {
      //   width: 240px;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #333333;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .del {
    width: 22px;
    height: 22px;
  }

  .pagination_contain {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 36px;
    height: 60px;
    background: #f7f7f7;
    border-radius: 2px;
    padding: 0 10px 0 20px;
    box-sizing: border-box;
    ::v-deep .el-pager .active {
      background: #23dc5a !important;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 46px;
      font-size: 20px;
    }
    ::v-deep .el-pager .number {
      background: #f2f2f3;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 46px;
      font-size: 20px;
    }
    ::v-deep .el-pager .btn-quicknext {
      background: #f2f2f3;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 60px;
      font-size: 20px;
    }

    ::v-deep .el-pager .btn-quickprev {
      background: #f2f2f3;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 60px;
      font-size: 20px;
    }
    ::v-deep .btn-prev {
      width: 46px;
      height: 46px;
      .el-icon-arrow-left {
        font-size: 24px;
      }
    }
    ::v-deep .btn-next {
      width: 46px;
      height: 46px;
      .el-icon-arrow-right {
        font-size: 24px;
      }
    }
    div:nth-child(1) {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #666666;
      letter-spacing: 0.63px;
    }
  }
}
</style>