<template>
  <div>
    <div class="contain">
      <div class="flex">
        <div class="h1">我的VIP</div>
        <div class="btn" @click="joinVip">加入VIP</div>
      </div>
      <div class="h1_line"></div>
      <el-table
        :data="list"
        style="width: 100%"
        header-cell-class-name="tabe_header"
      >
        <el-table-column label="VIP类型">
          <template slot-scope="scope">
            <div class="box1">
              <img
                v-if="JSON.parse(scope.row.config).id == 2"
                src="@/assets/image/icon58.png"
                alt=""
              />
              <img
                v-else-if="JSON.parse(scope.row.config).id == 1"
                src="@/assets/image/icon59.png"
                alt=""
              />
              <img
                v-else-if="JSON.parse(scope.row.config).id == 3"
                src="@/assets/image/icon57.png"
                alt=""
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column label="折扣">
          <template slot-scope="scope">
            {{ (JSON.parse(scope.row.config).discount).toFixed(1) }}折
          </template>
        </el-table-column>
        <el-table-column label="支付金额（元）" prop="price"> </el-table-column>
        <el-table-column label="有效期" prop="time" width="240">
          <template slot-scope="scope">
            <div>{{ JSON.parse(scope.row.config).day }}天</div>
          </template>
        </el-table-column>
        <el-table-column label="关联订单" width="320">
          <template slot-scope="scope"
            ><div style="cursor: pointer" @click="vipOrderDetail(scope.row)">
              {{ scope.row.order_no }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination_contain">
        <div>共{{ total || 0 }}项数据</div>
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page="page"
          :page-size="size"
          :total="total"
          @current-change="getList"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { postJSON } from "/utils/request";
import { formatDatetime } from "/utils/utils";

export default {
  name: "myVip",
  data() {
    return {
      list: [],
      page: 1,
      size: 20,
      total: 0,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    formatDatetime,
    getList(val) {
      // /api/v1/user/vip?page=1&size=20
      this.page = val || 1;
      postJSON("/order/list", {
        page: this.page,
        size: 20,
        status: 0, //不分状态
        type: 2, //vip
        // order_type 1文档 2vip 不传全部
      }).then((res) => {
        console.log("res", res);
        this.total = res.data.total;
        this.list = res.data.list;
      });
    },
    joinVip() {
      this.$router.push({ path: "/buyVip" });
    },
    vipOrderDetail(item) {
      console.log("item", item.status);
      // item.order_no
      let type = item.type; //2vip 1doc
      if (item.status == 1) {
        //1待支付

        this.$router.push({
          name: "payment",
          query: {
            order_no: item.order_no,
          },
        });
      } else {
        this.$router.push({
          path: "/orderDetail",
          query: {
            order_no: item.order_no,
            type,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contain {
  width: 1005px;
  //   height: 934px;
  background: #ffffff;
  border-radius: 6px;
  padding: 20px;
  box-sizing: border-box;
  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .h1 {
    font-size: 18px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    color: #333333;
  }
  .btn {
    width: 76px;
    height: 30px;
    background: linear-gradient(124deg, #facc60 0%, #ffc43b 54%, #f49f00 91%);
    border-radius: 3px;
    font-size: 13px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    line-height: 30px;
    cursor: pointer;
  }
  .h1_line {
    height: 1px;
    background: #f6f7f8;
    margin-bottom: 20px;
  }
  ::v-deep .tabe_header {
    background: #f6f7f8 !important;
  }
  .box1 {
    img {
      width: 68px;
      height: 22px;
    }
  }

  .pagination_contain {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 36px;
    height: 60px;
    background: #f7f7f7;
    border-radius: 2px;
    padding: 0 10px 0 20px;
    box-sizing: border-box;
    ::v-deep .el-pager .active {
      background: #23dc5a !important;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 46px;
      font-size: 20px;
    }
    ::v-deep .el-pager .number {
      background: #f2f2f3;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 46px;
      font-size: 20px;
    }
    ::v-deep .el-pager .btn-quicknext {
      background: #f2f2f3;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 60px;
      font-size: 20px;
    }

    ::v-deep .el-pager .btn-quickprev {
      background: #f2f2f3;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 60px;
      font-size: 20px;
    }
    ::v-deep .btn-prev {
      width: 46px;
      height: 46px;
      .el-icon-arrow-left {
        font-size: 24px;
      }
    }
    ::v-deep .btn-next {
      width: 46px;
      height: 46px;
      .el-icon-arrow-right {
        font-size: 24px;
      }
    }
    div:nth-child(1) {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #666666;
      letter-spacing: 0.63px;
    }
  }
}
</style>